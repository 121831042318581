<template>
  <div id="mian">
		<h5 class="jsxx ">基本信息</h5>
    <div class="input_con">
      <ul class="ul_left">
        <li><span>可取金额</span>
          <div class="in-block">1000.12</div>
        </li>
        <li><span>账号类型</span>
          <div class="in-block">
            <el-radio v-model="radio" label="1">对私</el-radio>
            <el-radio v-model="radio" label="2">对公</el-radio>
          </div>
        </li>
        <li>
          <span>账号卡号</span>
          <el-input class="inputs" v-model="input" placeholder="输入账号卡号"></el-input>
        </li>
        <li><span>代付金额</span>
          <el-input class="inputs" v-model="input" placeholder="输入代付金额"></el-input>
        </li>
        <li><span>代付说明</span>
          <el-input class="inputs" v-model="input" placeholder="输入代付说明"></el-input>
        </li>
      </ul>
      <ul class="ul_right">
        <li><span>可垫资金额</span>
          <div class="in-block">1000.12</div>
        </li>
        <li><span>账户名称</span>
          <el-input class="inputs" v-model="input" placeholder="输入账户名称"></el-input>
        </li>
        <li><span>联行号</span>
          <!-- 对私不展示 -->
          <el-input class="inputs" v-model="input" placeholder="输入商户证件编号"></el-input>
        </li>
        <li><span>代付用途</span>
          <el-select class="inputs" v-model="type" placeholder="选择代付用途">
            <el-option label="工资资金" value="0"></el-option>
          </el-select>
        </li>
        <li><span>支付密码</span>
          <el-input class="inputs" v-model="input" placeholder="输入客服电话"></el-input>
        </li>
        <li class="lianjie" @click="editPassShow=true">忘记密码</li>
      </ul>
    </div>
    <div class="bottom">
      <el-button class="next" type="primary" @click="successShow=true">下一步</el-button>
    </div>
    <el-dialog
      title="修改交易密码"
      :visible.sync="editPassShow"
      width="490px">
      <div class="overAccount_ul">
        <div>
          <span class="overAccount_ul_title">登录密码</span>
          <el-input class="inputs" v-model="input" placeholder="输入登录密码"></el-input>
        </div>
        <div>
          <span class="overAccount_ul_title">新密码</span>
          <el-input class="inputs" v-model="input" placeholder="输入新密码"></el-input>
        </div>
        <div>
          <span class="overAccount_ul_title">确认密码</span>
          <el-input class="inputs" v-model="input" placeholder="输入确认密码"></el-input>
        </div>
        <div>
          <span class="overAccount_ul_title">手机验证码</span>
          <el-input class="input" v-model="input" placeholder="输入手机验证码"></el-input>
          <el-button class="showBt" @click="editPassShow = false">获取验证码</el-button>
        </div>
        <div style="display: flex;">
          <span class="overAccount_ul_title">验证码</span>
          <el-input class="input" v-model="input" placeholder="输入验证码"></el-input>
          <img src="" alt="">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="editPassShow = false">取 消</el-button>
        <el-button class="addBt" @click="editPassShow = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="successShow"
      width="490px">
      <div class="overAccount_ul" style="padding-bottom: 20px;">
        <div>
          <span style="width: 100%;">请输入手机号码为139****1548的短信验证码</span>
        </div>
        <div>
          <span class="overAccount_ul_title">短信验证码</span>
          <el-input class="inputs" style="margin-bottom: 0;" v-model="input" placeholder="输入短信验证码"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="successShow = false">取 消</el-button>
        <el-button class="addBt" @click="successShow = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        fileList: [{
          name: 'food.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }, {
          name: 'food2.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }],
        imageUrl: '',
        editPassShow: false,
        successShow: false
      };
    },
    methods: {
      next() {
        this.$router.push("/user/businessList_shlb/addcommercial_jsxx")
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      }
    }
  }
</script>
<style scoped>
  .lianjie{
    color: #008AFF;
  }
  #fzcolor {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(72, 184, 182, 1);
    border-bottom: 3px solid rgba(72, 184, 182, 1);
  }

  .next {
    width: 120px;
    height: 32px;
    background: rgba(72, 184, 182, 1);
    border-color: #5AAFAB;
    border-radius: 3px;
    font-size: 12px;
    padding: 0;
  }

  .bottom {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 18px;
  }

  .djsc {
    width: 120px;
    height: 32px;
    background: rgba(72, 184, 182, 1);
    border-color: #5AAFAB;
    border-radius: 3px;
  }

  .upload_wj_right {
    float: left;
    margin-left: 200px;
    width: 22%;
    height: 100%;
  }

  .upload_wj h5 {
    padding: 29px 0 19px 0;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
  }

  .upload_wj_left {
    float: left;
    margin-left: 44px;
    width: 22%;
    height: 100%;
  }

  .upload_wj {
    margin-bottom: 25px;
    width: 100%;
    height: 180px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(239, 239, 239, 1);
    border-radius: 4px;
  }

  .uploader1 {
    margin-left: 30px;
    float: left;
  }
  .uploader1 h4{
    height: 52px;
    line-height: 52px;
    font-size: 14px;
    color: #000000;
  }
  .uploader1 h4 i{
    color: #48B8B6;
    padding-right: 3px;
  }
  .uploader2 {
    margin-left: 30px;
    margin-right: 19px;
    float: left;
  }

  .avatar-uploader {
    width: 120px;
    height: 120px;
    background: rgba(246, 246, 246, 1);
    border: 1px solid rgba(215, 218, 226, 1);
    border-radius: 4px;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
  .avatar {
    width: 120px;
    height: 120px;
    display: block;
  }
  .span4 {
    padding-left: 19px;
  }
  .span3 {
    padding-left: 19px;
  }
  .span2 {
    padding-left: 84px;
  }
  .span1 {
    padding-left: 30px;
  }
  .upload_ph p {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .upload_ph p span i {
    color: #48B8B6;
    padding-right: 3px;
  }
  .upload_ph p span {
    font-size: 14px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }
  .upload_ph {
    width: 100%;
    height: 200px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(239, 239, 239, 1);
    border-radius: 4px;
    margin-bottom: 20px;
  }
  .input_con ul li span {
    padding-right: 12px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
  }
  .input_con ul li span i {
    color: #48B8B6;
    padding-right: 3px;
  }
  .ul_right {
    float: right;
    height: 100%;
    text-align: right;
    margin-right: 28px
  }
  .ul_left {
    float: left;
    width: 492px;
    height: 100%;
    text-align: right;
  }
  .inputs {
    width: 360px;
    height: 36px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .input_con {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(239, 239, 239, 1);
    border-radius: 4px;
    padding-top: 30px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .header span {
    text-align: center;
    display: inline-block;
    width: 100px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }

  .header {
    margin-bottom: 20px;
    line-height: 40px;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid rgba(220, 224, 230, 1);
  }

  .title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 40px;
  }

  #mian {
    width: calc(100% - 41px);
    height: 97%;
    background: rgba(255, 255, 255, 1);
    padding: 0 20px;
    overflow-y: auto;
  }
  .in-block{
    display: inline-block;
    width: 360px;
    height: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .overAccount_ul{
    padding:20px 20px 0;
  }
  .overAccount_ul span{
    display: inline-block;
    width: 90px;
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    color: #999999;
  }
  .overAccount_ul .inputs{
    width: 360px;
  }
  .overAccount_ul .input{
    width: 250px;
    margin-bottom: 20px;
  }
  .addBt{
    width:60px;
    height:32px;
    background-color: #48B8B6;
    color: #FFFFFF;
    font-size: 12px;
    padding: 0;
  }
  .exitBt{
    width:60px;
    height:32px;
    background-color: #ffffff;
    color: #333333;
    font-size: 12px;
    padding: 0;
  }
  .overAccount_ul .showBt {
    float: right;
    background: #48B8B6;
    border-color: #48B8B6;
    margin-left: 20px;
    width: 90px;
    height: 36px;
    padding: 0;
    color: #FFFFFF;
  }
  .overAccount_ul img{
    display: inline-block;
    width: 90px;
    height: 36px;
    margin-left: 20px;
  }
	.jsxx {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 600;
		color: rgba(153, 153, 153, 1);
		padding-left: 14px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
</style>
